import Layout from "./components/Layout";

function App() {
  return (
    <div className="App bg-hero-lg">
     <Layout/>
    </div>
  );
}

export default App;
